<template>
  <div class="default-thank-you">
    <BaseNuxtImage
      src="https://a.storyblok.com/f/120667/320x376/934b2e19fa/hand.png?cv=1728384880953"
      :alt="t('common.form.thank_you.title')"
      class="default-thank-you__image"
    />
    <h4 class="default-thank-you__title">
      {{ t('common.form.thank_you.title') }}
    </h4>
    <p class="default-thank-you__text">{{ t('common.form.thank_you.text') }}</p>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()
</script>

<style lang="scss" scoped>
.default-thank-you {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__image {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 0;
    max-width: 424px;
  }
}
</style>
