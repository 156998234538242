<template>
  <div class="checkbox-group">
    <label class="mb-20">
      {{ label }}
      <span v-if="required" class="required"> *</span>
    </label>
    <div v-for="(option, index) in options" :key="option.id" class="checkbox">
      <label class="checkbox__label" :for="inputLabel + option.id">
        <input
          v-bind="$attrs"
          :id="inputLabel + option.id"
          type="checkbox"
          :checked="modelValue.includes(option.id)"
          :class="['checkbox__input', errorClass]"
          :name="option.en"
          :required="required"
          :data-testid="`${formatNameForTestid(name)}_${index}_checkbox_group`"
          @change="check($event, option)"
          @blur="v && v.$touch()"
        />
        {{ option.en }}
        <span class="checkbox__square" />
      </label>
    </div>
    <span v-if="showErrorMessage" class="error">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { Validation } from '@vuelidate/core'

defineOptions({
  inheritAttrs: false,
})

interface UseCase {
  de: string
  en: string
  id: string
  pl: string
  value: boolean
}
interface Props {
  modelValue: string[]
  options: UseCase[]
  label: string
  required?: boolean
  v?: Validation['ExtractRulesResults']
  name?: string
}
const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: string[]): void
}>()

const { errorMessage, errorClass, showErrorMessage, inputLabel } =
  useInput(props)

const check = (e: Event, option: UseCase) => {
  const updatedValue = [...props.modelValue]
  if ((e.target as HTMLInputElement).checked) {
    updatedValue.push(option.id)
  } else {
    updatedValue.splice(updatedValue.indexOf(option.id), 1)
  }
  emit('update:modelValue', updatedValue)
}
</script>
<style lang="scss" scoped>
.checkbox {
  margin-bottom: 20px;
  padding-left: 0;

  &__label {
    @include paragraph-s;

    position: relative;
    padding-left: 36px;
  }

  &__square {
    top: 0;
  }
}
</style>
