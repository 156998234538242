<template>
  <component
    :is="componentName"
    v-bind="$attrs"
    v-model="modelValue"
    v-editable="blok"
    :placeholder="blok.placeholder"
    :required="blok.required"
    :label="blok.label"
    :name="blok.name"
    :type="fieldType"
    :v="v && v[blok.name]"
    :options="dataOptions"
    :sort-alphabetically="blok.sort_alphabetically"
  />
</template>

<script lang="ts" setup generic="T">
import type { Validation } from '@vuelidate/core'
import type { FormInputStoryblok } from '@/types'

import FormBaseInput from '@/components/form/FormBaseInput.vue'
import FormBaseSelect from '@/components/form/FormBaseSelect.vue'
import FormBaseCheckboxGroup from '@/components/form/FormBaseCheckboxGroup.vue'
import FormBaseCheckbox from '@/components/form/FormBaseCheckbox.vue'
import FormBaseTextarea from '@/components/form/FormBaseTextarea.vue'
import FormBaseFilterSelect from '@/components/form/FormBaseFilterSelect.vue'

interface Props {
  blok: FormInputStoryblok
  v?: Validation<any, any>
}
const props = defineProps<Props>()
const modelValue = defineModel<string>()

const componentName = computed(() => {
  switch (props.blok.input_type) {
    case 'Input':
      return FormBaseInput
    case 'Select':
      return FormBaseSelect
    case 'FilterSelect':
      return FormBaseFilterSelect
    case 'CheckboxGroup':
      return FormBaseCheckboxGroup
    case 'Checkbox':
      return FormBaseCheckbox
    case 'Textarea':
      return FormBaseTextarea
    default:
      return FormBaseInput
  }
})
const fieldType = computed(() => {
  return props.blok.input_type === 'Input' ? props.blok.field_type : null
})

const dataOptions = computed(() => {
  if (
    ['Select', 'FilterSelect'].includes(props.blok.input_type) &&
    props.blok.data
  ) {
    return parseData(props.blok.data)
  }
  if (props.blok.input_type === 'CheckboxGroup' && props.blok.options) {
    return parseData(props.blok.options)
  }
  return null
})

const parseData = (data: string) => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return ''
  }
}
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
}
</style>
