<template>
  <FormBaseCheckbox
    v-model="fieldInput"
    v-editable="blok"
    :label-i18-path="consentPath"
    :required="blok.required"
    :name="blok.name"
    :v="v && v[blok.name]"
    class="checkbox-group__consent"
  >
    <nuxt-link :to="link">
      {{ $t('common.form.privacy_policy') }}
    </nuxt-link>
  </FormBaseCheckbox>
</template>

<script lang="ts" setup>
import type { Validation } from '@vuelidate/core'
import type { FormConsentStoryblok } from '@/types'
interface Props {
  modelValue: boolean
  blok: FormConsentStoryblok
  v?: Validation<any, any>
}

const { locale } = useI18n()

const link = computed(() => {
  return locale.value === 'en'
    ? '/privacy-policy'
    : `/${locale.value}/privacy-policy`
})

const consentPath = computed(() => {
  if (props.blok.text === '') {
    switch (props.blok.name) {
      case 'consentToProcess':
        return 'common.form.consents.sales_consent'
      case 'consentToSendNewsletter':
        return 'common.form.consents.newsletter_consent'
      case 'consentMarketing':
        return 'common.form.consents.receive_prepared_materials_consent'
      case 'consentUnified':
        return 'common.form.consents.unified_consent'
      default:
        return 'Choose consent name'
    }
  }
  return props.blok.text
})

const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()
const fieldInput = computed({
  get() {
    return props.modelValue
  },
  set(newValue: any) {
    emit('update:modelValue', newValue)
  },
})
</script>
