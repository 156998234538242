<template>
  <div class="load-overlay">
    <LoaderSpinner cobalt />
  </div>
</template>

<style lang="scss" scoped>
.load-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  z-index: 11;
}
</style>
